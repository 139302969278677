export const required = (value) => (value ? undefined : 'Required')
export const minValue = (min, minDisplay) => (value) =>
  value && value < min ? `Must be at least ${minDisplay ? minDisplay : min}` : undefined
export const maxValue = (max) => (value) =>
  value && value > max ? `Must be at least ${max}` : undefined
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined
export const phoneNumber = (value) =>
  value &&
  !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/i.test(value)
    ? 'Invalid phone number'
    : undefined
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
